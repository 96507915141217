.options h2 {
  text-align: center;
  padding: 50px 0;
}
.registerCards {
  display: grid;
  padding: 15px 60px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 15px;
}
.registerCard {
  border: 1px solid #000;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aliceblue;
}
.box {
  position: relative;
  height: 40%;
  background-color: rgb(77, 212, 84);
  width: 100%;
  margin-bottom: 3%;
}
.box img {
  position: absolute;
  border-radius: 50%;
  width: 15%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.title {
  font-weight: bold;
  padding: 10px 0;
  color: rgb(124, 0, 0);
  text-align: center;
}
.amount {
  font-weight: bold;
  color: #5f5f5f;
  padding-top: 6%;
}
.sub {
  padding: 15px 0;
  font-weight: bold;
  font-size: 18px;
  color: rgb(137, 137, 137);
}
.charges {
  padding: 15px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: disc;
}
.pad0 {
  padding: 5px 0;
}
