nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  background-color: rgba(7, 5, 69, 0.512);
  position: fixed;
  top: 0;
  z-index: 10;
}
.dark {
  background-color: rgb(7, 5, 69);
}
.left-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.left-nav img {
  height: 80%;
}
.right-nav {
  flex: 2;
  background-color: transparent;
}
.nav-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  height: 100%;
  justify-content: space-around;
}
.pop-menu {
  display: none;
  background: rgb(183, 181, 213);
  background: linear-gradient(
    90deg,
    rgba(183, 181, 213, 1) 0%,
    rgba(238, 252, 255, 1) 100%
  );
  list-style: none;
  position: absolute;
  padding: 10px;
  max-width: 200px;
  border-radius: 0 0 10px 10px;
}
.pop-menu li {
  padding: 5px 0;
  font-size: 16px;
  color: #070545;
}
.pop-menu li:hover {
  font-weight: bold;
  transition: 0.2s ease-in-out;
}
.nav-link {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 0 5px;
}
.nav-link:hover {
  color: rgb(184, 93, 149);
}
.nav-link:hover .pop-menu {
  display: block;
}
.toggle {
  background-color: transparent;
  border-radius: 50%;
  display: none;
  position: absolute;
  right: 10px;
}

@media screen and (max-width: 1000px) {
  .nav-list {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgb(7, 5, 69);
    padding: 10vh 0;
  }
  .slide {
    transform: translateX(100%);
  }
  .right-nav {
    transition: all 0.5s ease-in-out;
  }
  .toggle {
    display: block;
  }
}
