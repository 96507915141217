.home-panel {
  height: 100vh;
  background-image: url(../media/ditcampus.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
}
.darken {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.436);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-panel h2 {
  font-size: 40px;
  padding-bottom: 10px;
  max-width: 50%;
  text-align: center;
}
.home-panel h3 {
  font-size: 40px;
  padding: 10px 0;
}
.home-panel p {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0;
  color: #d2c72b;
}
.home-panel ul {
  list-style: none;
}
.home-panel li {
  font-size: 20px;
  font-weight: bold;
}
/*  */
.home-about {
  padding: 90px 50px;
}
.default-list {
  list-style: disc;
  padding-left: 20px;
  padding-top: 20px;
  color: rgb(37, 37, 37);
}
.dashed-list {
  list-style-type: "- ";
  color: rgb(37, 37, 37);
}
.dashed-list li {
  padding: 3px 0;
}
h4 {
  margin: 15px 0;
  font-weight: bold;
  text-decoration: underline;
}
.tracks {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
}
.icam {
  width: 200px;
}
