@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: 16px "Ubuntu", sans-serif;
  text-align: justify;
}
a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  cursor: pointer;
}
ul {
  list-style: none;
}
h1 {
  font-size: 34px;
  font-weight: bold;
}
h2 {
  font-size: 30px;
  font-weight: bold;
  padding: 25px 0;
}
h3 {
  font-size: 26px;
  font-weight: bold;
  padding: 30px 0;
}
main {
  padding-top: 100px;
}
.text {
  color: rgb(37, 37, 37);
}
button {
  background-color: #fbc234;
  color: #fff;
  margin: 10px 0;
  padding: 15px 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
