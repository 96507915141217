.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
}
.author-text {
  font-weight: bold;
}
.author-btn {
  margin: 10px;
}
.author-list {
  list-style: square;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.author-list li {
  color: rgb(52, 52, 52);
  padding: 5px 0;
  width: 80%;
}
.author-list li span {
  font-weight: bold;
}
