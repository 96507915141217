.centering {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centering .stack {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.centering .stack2 {
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-gap: 20px;
}
h2 {
  padding-top: 50px;
}
.stacks {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
}
