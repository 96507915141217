.home-dates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
  margin-top: 50px;
  place-items: center;
  min-width: 400px;
}
.home-dates h1 {
  transform: translateX(-30px);
  padding-bottom: 20px;
}
.dates-Div ul {
  list-style-image: url("../media/bullet.png");
}
.dates-Div li {
  padding: 10px 0;
}
.home-dates img {
  max-height: 500px;
  margin-right: 80px;
}
.dates-Div ul button {
  align-self: flex-start;
}
.text-like {
  grid-column: 1/3;
  padding: 15px 60px;
  text-align: center;
  color: rgb(163, 9, 9);
}

@media only screen and (max-width: 600px) {
  .dates-Div li {
    font-size: 14px;
    word-wrap: break-word;
  }
}
