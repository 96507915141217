.container {
  padding: 90px 50px;
}

.stack {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px;
}
.centering {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centering .stack3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.h2 {
  font-size: inherit;
  font-weight: inherit;
}
