footer {
  position: relative;
  background-color: rgb(7, 5, 69);
  margin-top: 100px;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 40px;
  padding: 60px 20px;
}
.footer-text {
  font-size: 16px;
}
.footer-text img {
  max-width: 150px;
  padding: 10px 0;
}
.footer-text ul {
  display: flex;
  padding: 10px 0;
}
.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(32, 32, 32, 0.553);
  padding: 10px;
  text-align: center;
}
.navss {
  color: rgb(138, 255, 127);
}
@media screen and (max-width: 600px) {
  footer {
    grid-template-columns: 1fr;
  }
}
/* Contact */
.footer-contacts {
  margin: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-contacts h5 {
  font-weight: bold;
  margin: 10px;
  font-size: 18px;
}
.footer-contacts p {
  margin: 5px;
}
.footer-contacts img {
  max-height: 200px;
  border-radius: 50%;
}
