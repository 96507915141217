.commonPanel {
  background-image: url(../media/panel-bg.jpg);
  height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commonPanel h1 {
  color: #fff;
  font-size: 50px;
  text-align: center;
}
