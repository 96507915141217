.About div {
  padding-left: 50px;
  padding-right: 50px;
}
.about-uni {
  padding-top: 100px;
}
.about-research {
  padding-bottom: 100px;
}
.divide {
  display: flex;
  flex-direction: row;
}
.ditgate {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 10px solid #fff;
  z-index: 2;
  box-shadow: 0 0 30px -10px rgb(37, 37, 37);
}
.divide p {
  flex: 1;
}
.divide span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px;
}
.bg {
  position: absolute;
  animation: bgmove 3s alternate infinite;
  width: 380px;
}
@keyframes bgmove {
  from {
    transform: rotateZ(-25deg);
  }
  to {
    transform: rotateZ(-20deg);
  }
}
