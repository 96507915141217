.card {
  display: flex;
  flex-direction: column;
  background-color: #b5b4677c;
  padding: 15px;
  box-shadow: 5px 5px 20px rgb(156, 155, 121);
}
.card:hover {
  box-shadow: none;
  transition: 0.5s;
}
.name {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 0;
  color: rgb(29, 29, 47);
}
.place {
  text-align: center;
  color: rgb(55, 55, 55);
}
.card img {
  border-radius: 50%;
  max-height: 200px;
}
.subtext {
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
  color: rgb(189, 10, 10);
}
